<template>
	<div class="my-home">
		<mainHeader></mainHeader>
		<div class="my-home-con clearfix">
			<div class="my-home-left">
				<myNavLeft></myNavLeft>
			</div>
			<div class="my-home-right">
				<!-- 内容 -->
				<router-view />
			</div>
		</div>
		<!-- 尾部 -->
		<!-- <mainFooter></mainFooter> -->
		
	</div>
</template>

<script>
	import mainHeader from "../../components/header.vue"
	import mainFooter from '../../components/footer.vue'
	import myNavLeft from '../../components/my-nav-left.vue'
	export default{
		components:{
			mainHeader,
			mainFooter,
			myNavLeft
		}
	}
</script>

<style lang='scss'>
	@import "../../assets/css/my-home.scss";
</style>
